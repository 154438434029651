<template lang="pug">
  .user-manage
    .container
      .row.justify-content-md-center
        .col-lg-5
          h1 パスワード再設定
          p メールアドレスに「認証コード」を送信しました。受信した認証コードと新しいパスワードを入力してください。
          div.mb-3 メールが届かない場合は
              router-link(to="/inquiry_form/input") こちら
              |よりお問い合わせください。
          ul.alert.alert-danger(v-if="messages.length > 0 && !errMessage")
            li(v-for="message in messages") {{ message }}
          .alert.alert-danger(v-if="errMessage") システムエラーが発生しました。
            div しばらく時間をおいてから再度お試しください。解決しない場合はお手数ですが
              router-link(to="/inquiry_form/input") こちら
              |よりをお問い合わせください。
          form.needs-validation(@submit.prevent="exec" novalidate)
            .form-group
              label(for="key") 認証コード
              input.form-control(type="text" placeholder="認証コード" v-model="key" required)
              .invalid-feedback 認証コードを入力してください
            .form-group
              label(for="password1") 新しいパスワード
              input#password1.form-control(type="password" minlength='8' maxlength='16' placeholder="パスワード（8~16文字の半角英数字）" v-model="password1" required)
              .invalid-feedback パスワードを8~16文字で入力してください
              div {{ textCount1 }}/16
              
            .form-group
              label(for="password2") 新しいパスワード（確認）
              input#password2.form-control(type="password" minlength='8' maxlength='16' placeholder="パスワード（確認）" v-model="password2" required)
              .invalid-feedback パスワードを8~16文字で入力してください
              div {{ textCount2 }}/16
            input.btn.btn-primary(type="submit" value="パスワードを再設定する" v-bind:disabled="isDisabled")
</template>

<script>
import {
    onfig,
    CognitoIdentityCredentials
} from 'aws-sdk'
import {
    CognitoUserPool,
    CognitoUser,
    AuthenticationDetails,
    CognitoUserAttribute
} from 'amazon-cognito-identity-js'

export default {
  data() {
    return {
      mail: this.$route.query.mail,
      password1: '',
      password2: '',
      key: '',
      messages: [],
      isDisabled: false,
      errMessage: false
    }
  },
  async created() {
    const poolData = {
      UserPoolId : process.env.VUE_APP_COGNITO_USER_POOL_ID,
      ClientId : process.env.VUE_APP_COGNITO_CLIENT_ID
    }
    this.userPool = new CognitoUserPool(poolData)

  },
  methods: {
    exec(e) {
      this.isDisabled = true

      this.messages = []

      this.errMessage = false

      this.key = this.key.trim()

      //パスワード一致チェック
      if ( this.password1 != this.password2 ) {
        this.messages.push('確認用のパスワードが一致しません　もう一度入力し直してください')
      }else if(this.password1.trim().length == 0 || this.password2.trim().length == 0){
        //パスワードがスペースのみの場合はAPIでエラーになるため、事前にチェックする
        this.messages.push('パスワードは空欄、又はスペースのみの入力はできません。')
      }

      if(this.messages.length > 0) {
        //入力中のパスワードをクリア
        this.password1 = ''
        this.password2 = ''
        this.isDisabled = false
        return
      }

      //エラー表示
      if (e.target.checkValidity() === false) {
        e.target.classList.add('was-validated')
        e.stopPropagation()
        this.isDisabled = false
        return
      }

      let verificationCode = this.key

      let newPassword = this.password2

      let userData = {
        Username : this.mail.toLowerCase(),
        Pool : this.userPool
      }

      this.$user.cognito = new CognitoUser(userData)
      
      //認証コード確認、パスワード再設定処理
      this.$user.cognito.confirmPassword(verificationCode, newPassword, {
        onSuccess: (result) => {
          // 処理結果OK
          this.$router.push('/user/password_reset/complete')
          this.isDisabled = false
        },
        onFailure: (err) => {  
          // 処理結果Error
          if(err.code == 'CodeMismatchException' || err.code == 'ExpiredCodeException') {
            err.message = '認証コードが正しくありません。'
          }else if(err.code == 'InvalidPasswordException') {
            err.message = 'パスワードは大小英数字８文字以上で入力してください。'
            //入力中のパスワードをクリア
            this.password1 = ''
            this.password2 = ''
          }else if(err.code == 'LimitExceededException' || err.code == 'TooManyRequestsException') {
            err.message = 'しばらく時間をおいてから再度お試しください。'
          }else {
            this.errMessage = true
          }
          this.messages.push(err.message)
          this.isDisabled = false
          return
        }
      })
    }
  },
  computed: {
    textCount1() { return this.password1.length },
    textCount2() { return this.password2.length },
  }
}
</script>